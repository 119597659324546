$mobile-resolution: 600px;
$global-font-family: 'Montserrat', 'Roboto', sans-serif;
$transparent-50: 80;

@function get-transparent-50-color($color) {
  @return unquote($color + '' + $transparent-50);
}
 
// primary
$primary-color: #FF8205;
$primary-color-400: #FF952B;
$primary-color-300: #FFA850;
$primary-color-200: #FFC182;
$primary-color-100: #FFDAB4;
$primary-color-50: #FFF0E1;
$primary-color-20: #fff6ee;
$primary-color-50-transparent: rgb(255 242 225 / 34%);

// secondary
$secondary-color: #7093F7;
$secondary-color-400: #85A3F8;
$secondary-color-300: #9BB3F9;
$secondary-color-200: #B8C9FB;
$secondary-color-100: #D4DFFD;
$secondary-color-50: #EEF2FE; 

// dark
$dark-color-500: #24232B;
$dark-color-400: #45444B;
$dark-color-300: #66656B;
$dark-color-200: #929195;
$dark-color-100: #BDBDBF;
$dark-color-50: #E5E5E6;

// light
$light-color-500: #BCBDCD;
$light-color-400: #C7C7C7;
$light-color-300: #D1D1D1;
$light-color-200: #DEDEDE;
$light-color-100: #EBEBEB;
$light-color-50: #F7F7F7;

// red
$error-color: #F52B2B;
$red-color-500: #DB2435;
$red-color-400: #E04553;
$red-color-300: #E66672;
$red-color-200: #ED929A;
$red-color-100: #F4BDC2;
$red-color-50: #FBE5E7;

// green
$green-color-500: #2DA051;
$green-color-400: #4DAE6B;
$green-color-300: #6CBD85;
$green-color-200: #96D0A8;
$green-color-100: #C0E3CB;
$green-color-50: #E6F4EA;

// other
$white-color: #fff;
$light-blue-color: #f1f4fe;
$light-blue-color-transparent-50: get-transparent-50-color($light-blue-color);
$text-gray-color: #767676;
$sidebar-color: #302D40;
$disabled-color: #BDBDBD;
$border-color: #EBEBEB;
$background-color: #F8F8F8;
$light-gray-color: #E5E5E5;
$background-light-gray-color: #f9f9f9;
$gray-color: #b3b3b3;
$blue-color: #70C6F7;
$yellow-color-500: #E9E241;
$yellow-color-100: #ebe697;
$yellow-color-50: #ebe697;
$yellow-color: #ec932d;
$teal-color-500: #6CE8D9;
$purple-color-50: #F3E9FC;

// gradient
$gradient: linear-gradient(116.58deg, #F8A453 -1.06%, #F26431 98.82%);
$shadow4: 0 2px 4px rgb(45 42 129 / 10%);

// screen
$tablet-width: 1200px;
$less-tablet-width: 1000px;
$mobile-width: 767px;
$small-mobile-width: 600px;
$unique-btn-color: rgb(255 130 5 / 8%);

:export {
  primaryColor: $primary-color;
  primaryColor400: $primary-color-400;
  primaryColor50: $primary-color-50;
  secondaryColor: $secondary-color;
  secondaryColor100: $secondary-color-100;
  secondaryColor50: $secondary-color-50;
  sidebarColor: $sidebar-color;
  disabledColor: $disabled-color;
  borderColor: $border-color;
  backgroundColor: $background-color;
  darkColor100: $dark-color-100;
  darkColor200: $dark-color-200;
  darkColor500: $dark-color-500;
  textGrayColor: $text-gray-color;
  greenColor500: $green-color-500;
  greenColor50: $green-color-50;
  redColor500: $red-color-500; 
  whiteColor: $white-color;
  lightColor500: $light-color-500;
  lightColor200: $light-color-200;
  lightColor100: $light-color-100;
  lightColor50: $light-color-50;
  lightBlueColor: $light-blue-color;
  blueColor: $blue-color;
  tealColor500: $teal-color-500;
  yellowColor500: $yellow-color-500;
  boxShadow4: $shadow4;
  redColor100: $red-color-100;
  secondaryColor200: $secondary-color-200;
}


$open-animation-duration: 0.35s;
$open-animation-timing: ease-in-out;
$textarea-font-size: 18px;
$textarea-min-height: 18px;
$textarea-max-height: 150px;
$loader-dot-background-color: rgba($light-color-500, 0.7);


.chat-widget {
  .cw-chat-launcher-container {
    position: relative;
  }
}

.cw-chat-window {
  opacity: 0;
  right: 20px;
  bottom: 20px;
  width: 440px;
  z-index: 1000;
  height: 600px;
  display: flex;
  position: fixed;
  overflow: hidden;
  border-radius: 10px;
  flex-direction: column;
  box-shadow: 0 4px 6px 0 rgb(0 0 0 / 15%);
  animation: openChat $open-animation-duration $open-animation-timing forwards;

  &__fullscreen {
    width: 90%;
    height: 90%;
  }

  @keyframes openChat {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}

.cw-chat-header {
  display: flex;
  padding: 20px;
  align-items: center;
  background-color: #EEF2FE;
  justify-content: space-between;

  &__content {
    display: flex;
    align-items: flex-start;
  }
  
  .cw-brand-icon-wrapper {
    position: relative;
  }

  .cw-brand-icon {
    width: 50px;
    height: 50px;
    display: flex;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 12px;
    position: relative;
    align-items: center;
    justify-content: center;
    background-color: white;
  }

  .cw-chat-status {
    bottom: 0;
    right: 14px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    position: absolute;
    display: inline-block;
    border: 2px solid white;
    background-color: #2DA051;
  }

  .cw-header-buttons {
    gap: 8px;
    display: flex;
    align-items: center;
  }

  .cw-header-button {
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }
  }

  .cw-header-text {
    color: #24232B;

    .cw-header-title {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 4px;
    }

    .cw-header-subtitle {
      font-size: 16px;
      font-weight: 400;
    }
  }
}

.cw-new-messages-count {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: red;
  color: white;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: bold;
}

.cw-chat-messages {
  flex: 1;
  display: flex;
  padding: 20px;
  overflow-y: auto;
  background: white;
  flex-direction: column;
}

.cw-message {
  font-size: 14px;
  font-weight: 400;
  border-radius: 8px;
  margin-bottom: 20px;
}

.cw-message-text {
  padding: 12px;
  line-height: 20px;
  border-radius: 8px;
  white-space: normal; 
  word-wrap: break-word; 
  word-break: break-word; 
  border: 1px solid #ECF0F7;
}

.cw-message-loader-dots {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: relative;
  display: inline-block;
  background-color: $light-color-500;
  animation: dots 1s infinite ease-in-out;
  box-shadow: 16px 0 0 $loader-dot-background-color, 32px 0 0 $loader-dot-background-color;
}

.cw-bot-message {
  width: 100%;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;

  .cw-bot-icon {
    width: 40px;
    height: 40px;
    display: flex;
    border-radius: 50%;
    margin-right: 12px;
    align-items: center;
    justify-content: center;
    background-color: white;
    border: 1px solid #ECF0F7;
  }

  .cw-message-text {
    flex: 1;  
  }
}

.cw-user-message {
  max-width: 100%;
  width: max-content;
  align-self: flex-end;
  background-color: #F5F6F9;
}

.cw-message-link {
  color: #4164F1;
  font-weight: 500;
  text-decoration: none;
  word-break: break-all;
}

.cw-chat-input {
  display: flex;
  padding: 10px 20px;
  background-color: white;
  border-top: 1px solid #ECF0F7;

  &__actions {
    display: flex;
    align-items: center;
    align-self: flex-end;
    padding-bottom: 8px;
  }

  label {
    min-height: 55px;
    flex: 1;
    display: flex;
    align-items: center;
    cursor: text;
  }

  textarea {
    width: 100%;
    height: auto;
    border: none;
    resize: none;
    outline: none;
    line-height: 1;
    font-weight: 400;
    color: $dark-color-400;
    font-size: $textarea-font-size;
    max-height: $textarea-max-height;
    min-height: $textarea-min-height;
    font-family: $global-font-family;

    &:disabled {
      background-color: transparent;
    }

    &:focus {
      outline: none;
    }

    &::placeholder {
      font-weight: 400; 
      line-height: 100%;
      color: $dark-color-300; 
      font-size: $textarea-font-size;
    }
  }

  &__send-button {
    svg {
      path {
        fill: $primary-color;
      }
    }
  }

  &__actions-speech {
    box-shadow: 0 0 0 rgba($primary-color-50, 0.5);

    &.active {
      background-color: $primary-color-50;
      animation: pulse 1s infinite;

      svg {
        path {
          fill: $primary-color;
        };
      }
    }
  }

  button {
    width: 40px;
    height: 40px;
    border: none;
    display: flex;
    cursor: pointer;
    margin-left: 8px;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    background-color: transparent;

    &:disabled {
      opacity: 0.3;
      pointer-events: none;

      svg {
        path {
          fill: #686767;
        }
      }
    }
    
    &:hover {
      opacity: 0.7;
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba($primary-color-50, 0);
  }

  50% {
    transform: scale(1.1);
    box-shadow: 0 0 1px 3px rgba($primary-color-50, 1);
  }

  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba($primary-color-50, 0);
  }
}

@keyframes dots {
  0% {
    background-color: $light-color-500;
    box-shadow: 16px 0 0 $loader-dot-background-color, 32px 0 0 $loader-dot-background-color;
  }

  33% {
    background-color: $loader-dot-background-color;
    box-shadow: 16px 0 0 $light-color-500, 32px 0 0 $loader-dot-background-color;
  }

  66% {
    background-color: $loader-dot-background-color;
    box-shadow: 16px 0 0 $loader-dot-background-color, 32px 0 0 $light-color-500;
  }
  
  100% {
    background-color: $light-color-500;
    box-shadow: 16px 0 0 $loader-dot-background-color, 32px 0 0 $loader-dot-background-color;
  }
}